<template>
  <div class="withdraw">
    <van-sticky>
      <van-nav-bar title="提取积分" left-arrow @click-left="goBack" />
    </van-sticky>
    <div class="w-head">
      <img width="128" height="128" :src="require('../assets/tixian.png')" />
      <p>可提取积分</p>
      <h2>{{ myinfo.income?myinfo.income.withdrawable:0 }}</h2>
    </div>
    <div class="w-input">
      <van-field
        v-model="value"
        type="digit"
        maxlength="8"
        placeholder="请输入提取积分"
      />
      <van-button type="primary" size="large" @click="gowithdraw()"
        >申请提取</van-button
      >
      <p><van-icon name="info-o" />满50即可提取，审核通过后发放！</p>
      <p class="w-prompt">
        目前为人工提取，每次提取时，请添加我们的客服人员微信并发送：“我要提取”的指令，即可快速到账
      </p>
    </div>
  </div>
</template>
<script>
import { showToast, showSuccessToast, showFailToast, showDialog } from "vant";
export default {
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      value: null,
      myinfo: { income: { withdrawable: 0 } },
      active: 2,
    };
  },
  filters: {
    currencyFormat: function (num) {
      num = num + "";
      let start = 0;
      if (num === "0") {
        return "0.00";
      } else {
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let $this = null;
      $this = this;
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        data: {},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          $this.myinfo = res.data;
          if (!$this.myinfo.userinfo) {
            window.sign(window.location.href);
          }
        } else {
          showFailToast("登录失败");
        }
      });
    },
    gowithdraw() {
      let $this = null;
      $this = this;
      //const withdrawable = 8000;
      const withdrawable = $this.myinfo.income.withdrawable;
      if ($this.value === "") {
        showFailToast("请输入提现积分");
      } else if ($this.value < 50) {
        showFailToast("最低提现积分为50");
      } else if (withdrawable < 50) {
        showFailToast("可提取积分不足");
      } else if ($this.value > withdrawable) {
        showFailToast("可提现积分不足" + $this.value + "分");
      } else {
        this.axios({
          url: this.$store.state.api + "/withdraw",
          method: "post",
          data: {
            amount: $this.value,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: this.Authorization,
          },
        }).then((res) => {
          if (res.data) {
            showDialog({
              title: "申请提取成功",
              message: "目前为人工提取，请联系客服人员快速提取。",
            }).then(() => {
              this.init();
            });
          } else {
            showFailToast("申请失败");
          }
        });
      }
    },
    currencyFormat(num) {
      num = num + "";
      let start = 0;
      if (num === "0") {
        return "0.00";
      } else {
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss">
body {
  background: #f5f5f5;
}
.van-nav-bar .van-icon-arrow-left {
  font-size: 24px;
  color: #969799;
}
.van-tabbar-item {
  font-size: 14px;
}
.withdraw {
  text-align: left;
  padding-bottom: 70px;
}
.withdraw .w-head {
  text-align: center;
  padding: 60px 0 0 0;
}
.w-head p {
  letter-spacing: 1px;
  color: #666666;
}
.w-head h2 {
  font-size: 40px;
  letter-spacing: 1px;
}
.w-input {
  margin: 20px;
}
.w-input .van-button {
  margin-top: 15px;
  border-radius: 30px;
  letter-spacing: 2px;
}
.w-input .van-cell {
  border-radius: 5px;
}
.w-input p {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  text-align: center;
}
.w-input .w-prompt {
  color: #fe0202;
}
.w-head .van-image {
  padding-left: 8px;
}
</style>
